(function() {
    if (typeof window.CustomEvent === 'function') {
        return false;
    }
  
    function CustomEvent<T>(type: string, eventInitDict?: CustomEventInit<T>) {
        const event = document.createEvent('CustomEvent');
        event.initCustomEvent(type, eventInitDict?.bubbles, eventInitDict?.cancelable, eventInitDict?.detail);
        return event;
    }
  
    CustomEvent.prototype = window.Event.prototype;
    (window as any).CustomEvent = CustomEvent;
})();

export {};
